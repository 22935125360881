// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-index-page-js": () => import("./../../../src/pages/index/IndexPage.js" /* webpackChunkName: "component---src-pages-index-index-page-js" */),
  "component---src-pages-index-index-page-styled-js": () => import("./../../../src/pages/index/IndexPage.styled.js" /* webpackChunkName: "component---src-pages-index-index-page-styled-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quiz-index-js": () => import("./../../../src/pages/quiz/index.js" /* webpackChunkName: "component---src-pages-quiz-index-js" */),
  "component---src-pages-quiz-old-index-js": () => import("./../../../src/pages/quiz-old/index.js" /* webpackChunkName: "component---src-pages-quiz-old-index-js" */),
  "component---src-pages-quiz-old-quiz-page-styled-js": () => import("./../../../src/pages/quiz-old/QuizPage.styled.js" /* webpackChunkName: "component---src-pages-quiz-old-quiz-page-styled-js" */),
  "component---src-pages-quiz-old-thank-you-styled-js": () => import("./../../../src/pages/quiz-old/ThankYou.styled.js" /* webpackChunkName: "component---src-pages-quiz-old-thank-you-styled-js" */),
  "component---src-pages-quiz-old-thankyou-js": () => import("./../../../src/pages/quiz-old/thankyou.js" /* webpackChunkName: "component---src-pages-quiz-old-thankyou-js" */),
  "component---src-pages-quiz-start-index-js": () => import("./../../../src/pages/quiz/start/index.js" /* webpackChunkName: "component---src-pages-quiz-start-index-js" */),
  "component---src-pages-quiz-start-styles-js": () => import("./../../../src/pages/quiz/start/styles.js" /* webpackChunkName: "component---src-pages-quiz-start-styles-js" */),
  "component---src-pages-quiz-styles-js": () => import("./../../../src/pages/quiz/styles.js" /* webpackChunkName: "component---src-pages-quiz-styles-js" */)
}

